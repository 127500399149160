'use strict';

var Css = require("bs-css/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Page$Paulshen = require("../ui/Page.bs.js");
var Text$Paulshen = require("../ui/Text.bs.js");
var Layer$Paulshen = require("./Layer.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var PhotoGrid$Paulshen = require("../ui/PhotoGrid.bs.js");
var PhotoOverlay$Paulshen = require("../ui/PhotoOverlay.bs.js");
var WindowDimensions$Paulshen = require("./WindowDimensions.bs.js");

var header = Css.style(/* :: */[
      Css.textAlign(Css.center),
      /* :: */[
        Css.marginBottom(Css.px(48)),
        /* [] */0
      ]
    ]);

var grid = Css.style(/* :: */[
      Css.display(Css.flexBox),
      /* [] */0
    ]);

var column = Css.style(/* :: */[
      Css.width(Css.pct(33.333333)),
      /* :: */[
        Css.padding2(Css.zero, Css.px(16)),
        /* :: */[
          Css.boxSizing(Css.borderBox),
          /* [] */0
        ]
      ]
    ]);

var item = Css.style(/* :: */[
      Css.marginBottom(Css.px(32)),
      /* [] */0
    ]);

var Styles = {
  header: header,
  grid: grid,
  column: column,
  item: item
};

var Unexpected = Caml_exceptions.create("AlbumPage-Paulshen.Unexpected");

function AlbumPage(Props) {
  var data = Props.data;
  console.log(data);
  var windowWidth = React.useContext(WindowDimensions$Paulshen.context);
  var match = React.useState((function () {
          return ;
        }));
  var setSelectedPhoto = match[1];
  var selectedPhoto = match[0];
  var match$1 = windowWidth > 840;
  var tmp;
  if (selectedPhoto !== undefined) {
    var selectedPhoto$1 = selectedPhoto;
    tmp = React.createElement(Layer$Paulshen.make, {
          children: React.createElement(PhotoOverlay$Paulshen.make, {
                thumbnails: data.albumsJson.thumbnails,
                images: data.albumsJson.full,
                startingIndex: data.albumsJson.full.findIndex((function (file) {
                        return file.relativePath === selectedPhoto$1;
                      })),
                onClose: (function (param) {
                    return Curry._1(setSelectedPhoto, (function (param) {
                                  return ;
                                }));
                  })
              })
        });
  } else {
    tmp = null;
  }
  return React.createElement(Page$Paulshen.make, {
              children: null,
              title: data.albumsJson.title
            }, React.createElement("div", {
                  className: header
                }, React.createElement(Text$Paulshen.Header.make, {
                      children: data.albumsJson.title,
                      size: /* S2 */18559,
                      tag: /* H1 */16105
                    })), React.createElement(PhotoGrid$Paulshen.make, {
                  photos: data.albumsJson.photos.map((function (photo) {
                          return /* Local */Block.__(1, [photo.src]);
                        })),
                  photoFiles: data.albumsJson.full,
                  numColumns: match$1 ? 3 : 2,
                  onClick: (function (src) {
                      if (src.tag) {
                        var path = src[0];
                        return Curry._1(setSelectedPhoto, (function (param) {
                                      return path;
                                    }));
                      } else {
                        throw Unexpected;
                      }
                    })
                }), tmp);
}


const { graphql } = require('gatsby');
exports.pageQuery = graphql`
  query AlbumQuery($id: String) {
    albumsJson(id: { eq: $id }) {
      id
      title
      photos {
        src
      }
      thumbnails: photoFiles {
        relativePath
        childImageSharp {
          fluid(maxWidth: 300, quality: 70) {
            ...GatsbyImageSharpFluid
            presentationWidth
          }
        }
      }
      full: photoFiles {
        relativePath
        childImageSharp {
          fluid(maxWidth: 1600, quality: 70) {
            ...GatsbyImageSharpFluid_noBase64
            presentationWidth
          }
        }
      }
    }
  }
`

;

var make = AlbumPage;

var $$default = AlbumPage;

exports.Styles = Styles;
exports.Unexpected = Unexpected;
exports.make = make;
exports.$$default = $$default;
exports.default = $$default;
exports.__esModule = true;
/* header Not a pure module */
